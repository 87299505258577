import { NotificationDetailEntity } from "domain/entity/NotificationDialog/NotificationDetailEntity";
import { NotificationHeaderEntity } from "domain/entity/NotificationDialog/NotificationHeaderEntity";
import { NotificationDialogRepository } from "domain/repository/NotificationDialog/NotificationDialogRepo";
import _ from "lodash";
import { NotificationDialogCard, NotificationDialogModel } from "presentation/model/NotificationDialog/NotificationDialogModel";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";

interface NotificationDialogVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<NotificationDialogModel>> | ((value: SetStateAction<NotificationDialogModel>) => void),
    ]
    notificationDialogRepo: NotificationDialogRepository,
}

const showNotificationCount: number = Number(process.env.REACT_APP_SHOW_NOTIFICATION_COUNT);

export const NotificationDialogVM = ({ dispatch, notificationDialogRepo }: NotificationDialogVMProps) => {
    const [notificationDialogDispatch] = dispatch;

    const getNotificationsByUser = async (userName: string) => {
        await notificationDialogRepo.getNotificationByUser(userName).then((data) => {
            if (data) {
                notificationDialogDispatch(prevState => {
                    //get the new notifications which it is not existed in the previous state
                    const orginalIds = new Set([...prevState.allNotifications, ...prevState.showNotifications]?.map((notification) => notification.notificationId));
                    const newNotificationHeaders: NotificationHeaderEntity[] = data?.filter((notification) => !orginalIds.has(notification.id));
                    const newNotificationCards: NotificationDialogCard[] = _.sortBy(newNotificationHeaders, "priority")?.map((notification) => {
                        const notificationCard: NotificationDialogCard = {
                            isActiveScreen: false,
                            notificationId: notification.id,
                            heading: notification.title,
                            text: notification.content,
                            width: "400px",
                            height: "auto",
                            id: String(notification.id),
                            disabledIcon: notification.level === "ALERT" ? false : true,
                        }
                        return notificationCard;
                    })

                    //merge all notification cards
                    const newAllNotificationCards = [...prevState.allNotifications, ...newNotificationCards];
                    const restOfShow = showNotificationCount - prevState.showNotifications.length;

                    return {
                        ...prevState,
                        allFormState: {},
                        allNotifications: restOfShow > 0 ? newAllNotificationCards.slice(restOfShow) : newAllNotificationCards,
                        showNotifications: [...prevState.showNotifications, ...(restOfShow > 0 ? newAllNotificationCards.slice(0, restOfShow) : [])]
                    }
                });
            }
        }).catch((error) => {
            return null;
        })

    }

    const onFeedbackNotification = async (feedback: NotificationDialogCard, feedbackStatus: string, userName: string) => {
        const notificationDetail: NotificationDetailEntity = {
            notificationId: feedback.notificationId ?? 0,
            feedbackContent: feedback.feedbackContent ?? "",
            feedbackStatus: feedbackStatus,
            feedbackUser: userName,
            feedbacDate: new Date()
        }
        return await notificationDialogRepo.feedbackNotification(notificationDetail).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                notificationDialogDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: { "feedbackNotificationFail": data.toString() },
                        allNotifications: [...prevState.allNotifications.slice(1)],
                        showNotifications: [...prevState.showNotifications?.filter(card => card.notificationId !== feedback.notificationId), ...prevState.allNotifications.slice(0, 1)]
                    };
                });
                return { "feedbackNotificationFail": data.toString() };
            } else {
                notificationDialogDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: { "feedbackNotificationSuccess": "successful" },
                        allNotifications: [...prevState.allNotifications.slice(1)],
                        showNotifications: [...prevState.showNotifications?.filter(card => card.notificationId !== feedback.notificationId), ...prevState.allNotifications.slice(0, 1)]
                    };
                });
                return { "feedbackNotificationSuccess": "successful" };
            }
        }).catch(error => {
            notificationDialogDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: { "feedbackNotificationFail": error.message }
                }
            });
            return { "feedbackNotificationFail": error.message };
        })
    }

    return {
        getNotificationsByUser: getNotificationsByUser,
        onFeedbackNotification: onFeedbackNotification,
    }
}