import { NavigationEntity, SubNavigationEntity, } from "domain/entity/Navigation";
import { AclType, AllNbisivPermission } from "presentation/constant/ANAInfo/NbisivPermission";
import { isPermissionExist } from "presentation/utils/permissionUtils";

interface RecursionInterface extends NavigationEntity, SubNavigationEntity {}

export default function recursionPropertyInsertion(
  arrObj: RecursionInterface[] = [],
  props = {}
): RecursionInterface[] {
  const newArr = arrObj?.map(function recur(item) {
    item = { ...item, ...props };
    Array.isArray(item.submenu) && (item.submenu = item.submenu?.map(recur));
    Array.isArray(item.navigationList) &&
      (item.navigationList = item.navigationList?.map(recur));
    return item;
  });

  return newArr;
}


/**
 * Filter the menu items which no assigned the permission for user
 * @param navigations 
 * @param permissions 
 * @param operatingCompany 
 * @returns 
 */
// export function filterNavByPermissions(navigations: NavigationEntity[], permissions:AllNbisivPermission, operatingCompany:string) {
//   let filteredNav:NavigationEntity[] = [];
//   let groupItem:NavigationEntity | null = null;
//   let separatorItem:NavigationEntity | null = null;
//   let addSeparator:boolean = false;
//   filteredNav = navigations.reduce((filtered: NavigationEntity[], navItem: NavigationEntity) => {
//     if (navItem.separator === true) {
//       separatorItem = navItem;
//     }

//     if (navItem.type === "Group") {
//       groupItem = navItem;
//     }
      
//     if (separatorItem && addSeparator) {
//       filtered.push({...separatorItem});
//       separatorItem = null;
//       addSeparator = false;
//     }
    
//     if (checkPermission(navItem.permission??"", permissions, operatingCompany)) {  
//         const filteredSubMenu = navItem.submenu ? filterNavByPermissions(navItem.submenu, permissions, operatingCompany) : undefined;
//         if ((filteredSubMenu && filteredSubMenu?.length > 0) || !navItem.submenu) {
//             if (groupItem) {
//               filtered.push({...groupItem});
//               groupItem = null;
//             }
//             addSeparator = true;
//             filtered.push({ ...navItem, submenu: filteredSubMenu });  
//         }  
//     } else if (navItem.submenu) {
//         const filteredSubMenu = navItem.submenu ? filterNavByPermissions(navItem.submenu, permissions, operatingCompany) : undefined;
//         if ((filteredSubMenu && filteredSubMenu?.length > 0) || !navItem.submenu) {
//           if (groupItem) {
//             filtered.push({...groupItem});
//             groupItem = null;
//           }
//           addSeparator = true;
//           filtered.push({ ...navItem, submenu: filteredSubMenu });  
//       }
//     }

//     return filtered;  
//   }, []);
//   return filteredNav;
// }

export function filterNavByPermissions(navigations: NavigationEntity[], permissions: AllNbisivPermission, operatingCompany: string): NavigationEntity[] {
  const filteredNav: NavigationEntity[] = [];
  let groupItem: NavigationEntity | null = null;
  let addSeparator: boolean = false;

  navigations?.forEach((navItem, index) => {
    if (navItem.type === "Group") {
      groupItem = navItem;
    } else if (navItem.separator === true) {
      // Add the separator if addSeparator is true and the current item is not the last item
      // Also, ensure the current item is not a group item
      if (addSeparator && index !== navigations.length - 1 && navItem.type !== "Group") {
        filteredNav.push({ ...navItem });
        addSeparator = false;
      }
    } else {
      // Check permission for regular items
      if (checkPermission(navItem.permission ?? "", permissions, operatingCompany)) {
        let filteredSubMenu: NavigationEntity[] | undefined;
        if (navItem.submenu) {
          filteredSubMenu = filterNavByPermissions(navItem.submenu, permissions, operatingCompany);
        }

        // Add the item if it doesn't have a submenu or if it does and the filtered submenu has content
        if (!navItem.submenu || (filteredSubMenu && filteredSubMenu.length > 0)) {
          // Add the group item before the current item if it exists
          if (groupItem) {
            filteredNav.push({ ...groupItem });
            groupItem = null;
          }
          addSeparator = true;
          filteredNav.push({ ...navItem, submenu: filteredSubMenu });
        }
      } else if (navItem.submenu) {
        // Recursively check sub-menu items even if the parent item is not accessible
        const filteredSubMenu = filterNavByPermissions(navItem.submenu, permissions, operatingCompany);
        if (filteredSubMenu.length > 0) {
          // Add the group item before the current item if it exists
          if (groupItem) {
            filteredNav.push({ ...groupItem });
            groupItem = null;
          }
          addSeparator = true;
          filteredNav.push({ ...navItem, submenu: filteredSubMenu });
        }
      }
    }
  });

  // No need to add a separator at the end of the array
  // Remove any code that would do so

  return filteredNav;
}

function checkPermission(code:string, permissions:AllNbisivPermission, operatingCompany:string) {
  if (!code || !permissions || !operatingCompany) return false;

  const passed = isPermissionExist(code, AclType.READ, operatingCompany, permissions);
  return passed;
}
