/* eslint-disable array-callback-return */
import { ArpitaRepoImpl } from "domain/repository/Arpita/ArpitaRepoImpl";
import { NavigationRepositoryImpl } from "domain/repository/Navigation";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent, customEventListener } from "helpers/Events";
import { useANAInfo } from "presentation/hook/ANAInfo/useANAInfo";
import { useWorkspaceTracked } from "presentation/store/NavigationMenu/NavigationMenu";
import { retrieveUniqueMFENavigation } from "presentation/utils/menuUtil";
import NotificationDialogComponent from "presentation/view/components/NotificationDialog/NotificationDialogComponent";
import { MicroFrontend } from "presentation/view/components/workspace/MicroFrontend/MicroFrontend";
import { Notification } from "presentation/view/components/workspace/Notification/Notification";
import MainViewVM from "presentation/viewModel/workspace/MainViewVM/MainViewVM";
import NavigationMenuVM from "presentation/viewModel/workspace/NavigationMenuVM/NavigationMenuVM";
import { useEffect, useMemo, useRef, useState } from "react";
import { Core } from "veronica-ui-component";

const { Container, IconButton, } = Core;

// [IMP] Don't remove the commented code, that has to be decided later for usage.
const MainView = ({ data }: any) => {
    const resizableRef = useRef(null);
    const moduleRowRef = useRef<HTMLDivElement>(null);
    const [workspaceState, setWorkspaceState] = useWorkspaceTracked()
    const { navigation } = new NavigationRepositoryImpl().getNavigation(workspaceState.mainMenu);
    const { entrypoint, path, sectionId, showMainView, mfeTitle, miniToolbarArr, expanded, dragSubModArr, modulesData, mfeSupTitle, mainNavTitle, mfeFullscreen, mfeGraphic, notification, navigationBarPinned } = workspaceState;
    const { userName } = useANAInfo();

    const mainViewVM = useMemo(() => MainViewVM({ dispatch: [setWorkspaceState], }), [setWorkspaceState]);
    const navigationMenuVM = useMemo(() => NavigationMenuVM({ dispatch: [setWorkspaceState], arpitaRepo: ArpitaRepoImpl() }), [setWorkspaceState]);
    const [eventData, setEventData] = useState<{ [key: string]: any }>();

    const resizable: any = resizableRef?.current;

    const onExpand = (e: any): void => {
        setWorkspaceState(prevState => ({
            ...prevState,
            expanded: true,//!expanded,
            showVersion: false,
            mainViewWidth: `${resizable?.clientWidth}px`,
        }))
    };

    // const onDragStart = (e: any): void => {
    //   setInitialPos(e.clientX);
    //   resizable && setInitialSize(resizable.offsetWidth);
    // };

    // const onDrag = (e: any): void => {
    //   if (resizable) {
    //     resizable.style.width = `${initialSize + (e.clientX - initialPos)}px`;
    //   }
    //   dispatch({ ...workspaceState, mainViewWidth: `${resizable?.clientWidth}px` });
    // };

    const onClose = (e: any): void => {
        mainViewVM.onCloseMFE(e, userName);
    };

    const drop = (e: any) => {
        e.preventDefault();
        e.target.classList.remove("drag-over");
        const id = e.dataTransfer.getData("dropElemRightBar");
        const mainBarModId = e.dataTransfer.getData("dropElemMain");
        const draggable = document.getElementById(id);
        if (draggable) {
            miniToolbarArr?.forEach((item: any) => {
                if (item.id !== Number(id)) {
                    return item;
                }
            });
        }
        setWorkspaceState(prevState => ({
            ...prevState,
            miniToolbarArr: miniToolbarArr,
        }))
        const draggables = document.getElementById(mainBarModId);
        if (draggables) {
            draggables.style.left = e.clientX - 55 + "px";
            draggables.style.top = e.clientY + "px";
            draggables.style.overflow = "auto";
            draggables.style.resize = "both";
            draggables.style.position = "absolute";

            miniToolbarArr?.forEach((item: any) => {
                if (item.id === Number(mainBarModId)) {
                    item.left = e.clientX - 55;
                    item.top = e.clientY;
                }
            });
        }
        miniToolbarArr?.forEach((item: any) => {
            if (item.id === Number(id)) {
                item.drop = true;
                item.left = e.clientX - 55;
                item.top = e.clientY;
                item.width = "250"
                item.height = "250"
            }
        });
        miniToolbarArr?.forEach((item: any) => {
            if (item.drop === true) {
                const rlhw = document.getElementById(item.id)?.getBoundingClientRect();
                const modData = {
                    id: item.id,
                    title: item.title,
                    supModExpanded: item?.expanded,
                    disabled: item.disabled,
                    container: item.container,
                    top: rlhw?.top,
                    left: rlhw?.left,
                };
                const dropDublicate: any = dragSubModArr.findIndex(
                    (id: any) => id.id === item.id
                );

                dropDublicate >= 0
                    ? (dragSubModArr[dropDublicate] = modData)
                    : setWorkspaceState(prevState => ({
                        ...prevState,
                        dragSubModArr: [...dragSubModArr, modData]
                    }))
            }
        });
    };

    const dragstart_handler = (e: any) => {
        e.dataTransfer.setData("dropElemMain", e.target.id);
    };

    const dragEnter = (e: any) => {
        e.preventDefault();
    };

    const dragOver = (e: any) => {
        e.preventDefault();
        e.target.classList.add("drag-over");
    };

    const newDropArr = miniToolbarArr?.filter((item: any) => item.drop === true);

    const closeBox = (id: any) => {
        miniToolbarArr?.forEach((item: any) => {
            if (item.id === id) {
                item.disabled = false;
                item.expanded = false;
                item.close = true;
            }
        });
        const removeData = modulesData?.filter((item: any) => item.id !== id);
        const remainingModuless = miniToolbarArr?.filter(
            (item: any) => item.id !== id
        );
        setWorkspaceState(prevState => ({
            ...prevState,
            modulesData: removeData,
            miniToolbarArr: remainingModuless,
        }))
    };

    const expandBox = (id: any) => {
        const n = miniToolbarArr?.map((item: any) => {
            if (item.id === id) {
                item.expanded = !item.expanded;
            }
            return item;
        });
        setWorkspaceState(prevState => ({
            ...prevState,
            miniToolbarArr: n
        }))
    };

    const openFullscreen = (e: any) => {
        setWorkspaceState(prevState => ({
            ...prevState,
            mfeFullscreen: true
        }))
    }

    const closeFullscreen = (e: any) => {
        setWorkspaceState(prevState => ({
            ...prevState,
            mfeFullscreen: false
        }))
    }

    const goToMFE = (e: any) => {
        const res: any = navigation && [...navigation]?.filter(function f(o: any) {
            if (o.entrypoint && o.entrypoint.includes(e.detail)) return true

            if (o.submenu) {
                return (o.submenu = o.submenu?.filter(f)).length
            }
        })

        if (!e.submenu) {
            const getNavObj: any = (rs: any) => {
                let r: any = {};
                if (rs) {
                    r = rs[0] ? rs[0] : {};
                    if (r?.submenu?.length) {
                        return getNavObj(r.submenu);
                    }
                }

                return r;
            }

            navigationMenuVM.onOpenMFE(getNavObj(res), userName);
        }
    }

    const setPageInformation = (e: any) => {
        mainViewVM.setPageInformation(e.detail);
    }

    const getPageInformation = (e: any) => {
        const getPageInformation = new CustomEvent('getPageInformation', {
            detail: {
                isMainMenu: workspaceState.isMainMenu,
                mfePageInformation: workspaceState.mfePageInformation,
                defaultPageInformation: workspaceState.defaultPageInformation,
                username: userName
            }
        });
        document.dispatchEvent(getPageInformation);
    }

    const portCodeOnBerthPlan = (e: any) => {
        navigationMenuVM.onOpenMFE(e.detail, userName);
    }

    useEffect(() => {
        document.addEventListener('openFullscreen', openFullscreen as EventListener)
        document.addEventListener('closeFullscreen', closeFullscreen as EventListener)
        document.addEventListener('goToMFE', goToMFE as EventListener)
        document.addEventListener('setPageInformation', setPageInformation as EventListener)
        document.addEventListener('onPageLoad', getPageInformation as EventListener);
        document.addEventListener('showPortCodeOnBerthPlan', portCodeOnBerthPlan as EventListener);
        document.addEventListener('goToBerthPlan', portCodeOnBerthPlan as EventListener);
        return () => {
            document.removeEventListener('openFullscreen', openFullscreen as EventListener)
            document.removeEventListener('closeFullscreen', closeFullscreen as EventListener)
            document.removeEventListener('goToMFE', goToMFE as EventListener)
            document.removeEventListener('setPageInformation', setPageInformation as EventListener)
            document.removeEventListener('onPageLoad', getPageInformation as EventListener);
            document.removeEventListener('showPortCodeOnBerthPlan', portCodeOnBerthPlan as EventListener);
            document.removeEventListener('goToBerthPlan', portCodeOnBerthPlan as EventListener);
        };
    });

    useEffect(() => {

        const onChargeDetailOpen = async (e: any) => {
            const data = e.detail.data;
            const navigationList = workspaceState.mainMenu.navigation;
            const item = navigationList?.find((nav) => nav.entrypoint === "chargeDetailMaintenance");
            navigationMenuVM.onOpenMFE(item, data.userName);
        }

        const onManualChargeDetailOpen = async (e: any) => {
            const data = e.detail.data;
            const navigationList = workspaceState.mainMenu.navigation;
            const item = navigationList?.find((nav) => nav.entrypoint === "manualChargeDetailMaintenance");
            navigationMenuVM.onOpenMFE(item, data.userName);
        }


        const onNonOpsDetailOpen = async (e: any) => {
            const data = e.detail.data;
            const navigationList = workspaceState.mainMenu.navigation;
            const item = navigationList?.find((nav) => nav.entrypoint === "nonOpsDetailMaintenance");
            navigationMenuVM.onOpenMFE(item, data.userName);
        }

        const onDocumentEnquiryDetailOpen = async (e: any) => {
            const data = e.detail.data;
            const navigationList = workspaceState.mainMenu.navigation;
            const item = navigationList?.find((nav) => nav.entrypoint === "documentEnquiryDetail");
            navigationMenuVM.onOpenMFE(item, data.userName);
        }

        const onCreditNoteMaintOpen = async (e: any) => {
            const data = e.detail.data;
            const navigation = workspaceState.mainMenu.navigation?.find((nav) => nav.title === "Charge");
            const item = navigation?.submenu?.find((submenu) => submenu.entrypoint === "creditNoteMaintenance");
            navigationMenuVM.onOpenMFE(item, data.userName);
        }


        const onChargeDetailRedirect = async (e: any) => {
            switch (e.detail.action) {
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onChargeDetailOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH]: e.detail.data.data });

                    break;
                case E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onManualChargeDetailOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH]: e.detail.data.data });

                    break;
                case E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onNonOpsDetailOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH]: e.detail.data.data });

                    break;
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onChargeDetailOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY]: e.detail.data.data });

                    break;
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onChargeDetailOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY]: e.detail.data.data });

                    break;
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onChargeDetailOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA]: e.detail.data.data });

                    break;
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onChargeDetailOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY]: e.detail.data.data });

                    break;
            }
        }

        const onDocumentDetailRedirect = async (e: any) => {
            switch (e.detail.action) {
                case E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onDocumentEnquiryDetailOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER]: e.detail.data.data });

                    break;
            }
        }

        const onCreditNoteMaintRedirect = async (e: any) => {
            switch (e.detail.action) {
                case E_Custom_Dispatch_Event.REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onCreditNoteMaintOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE]: e.detail.data.data });

                    break;
            }
        }

        customEventListener(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT, onChargeDetailRedirect as EventListener);
        customEventListener(E_Type_Of_Event.DOCUMENT_ENQUIRY_REDIRECT_EVENT, onDocumentDetailRedirect as EventListener);
        customEventListener(E_Type_Of_Event.CREDIT_NOTE_MAINT_REDIRECT_EVENT, onCreditNoteMaintRedirect as EventListener);
    }, [navigationMenuVM, workspaceState.mainMenu.navigation]);

    useEffect(() => {
        const onChargeHeaderOpen = async (e: any) => {
            const data = e.detail.data;
            let navigationList = workspaceState.mainMenu.navigation;
            navigationList = retrieveUniqueMFENavigation(navigationList ?? []);
            const item = navigationList?.find((nav) => nav.entrypoint === "chargeHeaderMaintenance");
            navigationMenuVM.onOpenMFE(item, data.userName);
        }

        const onChargeHeaderRedirect = async (e: any) => {
            switch (e.detail.action) {
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onChargeHeaderOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY]: e.detail.data.data });

                    break;
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onChargeHeaderOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY]: e.detail.data.data });

                    break;
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onChargeHeaderOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY]: e.detail.data.data });

                    break;
            }
        }

        customEventListener(E_Type_Of_Event.CHARGE_HEADER_REDIRECT_EVENT, onChargeHeaderRedirect as EventListener);
    }, [navigationMenuVM, workspaceState.mainMenu.navigation]);

    useEffect(() => {
        const onChangeKeyDataOpen = async (e: any) => {
            const data = e.detail.data;
            let navigationList = workspaceState.mainMenu.navigation;
            navigationList = retrieveUniqueMFENavigation(navigationList ?? []);
            const item = navigationList?.find((nav) => nav.entrypoint === "changeKeyDataMaintenance");
            navigationMenuVM.onOpenMFE(item, data.userName);
        }

        const onChangeKeyDataRedirect = async (e: any) => {
            switch (e.detail.action) {
                case E_Custom_Dispatch_Event.REDIRECT_CHANGE_KEY_DATA_FROM_CHARGE_DETAIL:
                    if (!workspaceState.mainMenu.navigation) break;

                    await onChangeKeyDataOpen(e);
                    setEventData({ [E_Custom_Dispatch_Event.REDIRECT_CHANGE_KEY_DATA_FROM_CHARGE_DETAIL]: e.detail.data.data });

                    break;
            }
        }

        customEventListener(E_Type_Of_Event.CHANGE_KEY_DATA_REDIRECT_EVENT, onChangeKeyDataRedirect as EventListener);
    }, [navigationMenuVM, workspaceState.mainMenu.navigation]);

    useEffect(() => {
        if (eventData) {
            const timerId = setTimeout(() => {
                if (eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH]) {
                    customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH] });
                }
                if (eventData[E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH]) {
                    customDispatchEvent(E_Type_Of_Event.MANUAL_CHARGE_DETAIL_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH] });
                }

                if (eventData[E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH]) {
                    customDispatchEvent(E_Type_Of_Event.NON_OPS_DETAIL_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH] });
                }
                if (eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY]) {
                    customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY] });
                }
                if (eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY]) {
                    customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY] });
                }
                if (eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY]) {
                    customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY] });
                }
                if (eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY]) {
                    customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY] });
                }
                if (eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA]) {
                    customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA] });
                }
                if (eventData[E_Custom_Dispatch_Event.REDIRECT_CHANGE_KEY_DATA_FROM_CHARGE_DETAIL]) {
                    customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_CHANGE_KEY_DATA_FROM_CHARGE_DETAIL,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_CHANGE_KEY_DATA_FROM_CHARGE_DETAIL] });
                }
                if (eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY]) {
                    customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY] });
                }
                if (eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY]) {
                    customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY] });
                }


                if (eventData[E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER]) {
                    customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_DETAIL_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER] });
                }

                if (eventData[E_Custom_Dispatch_Event.REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE]) {
                    customDispatchEvent(E_Type_Of_Event.CREDIT_NOTE_MAINT_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE] });
                }
            }, 300);

            return () => { clearTimeout(timerId) }
        }
    }, [eventData]);

    const parentTitle = mainNavTitle?.length ? mainNavTitle : mfeSupTitle;
    return <div onDragOver={dragOver} onDragCapture={dragEnter} onDrop={drop} className={`main-container ${expanded ? "expanded" : ""} ${mfeFullscreen ? (navigationBarPinned ? "" : "overlay-active") : ""}`} ref={resizableRef} id="main">
        <Container borderRadius="roundAll" height="100%" onClick={() => { }} theme="theme4" width="100%" className={mfeGraphic ? "mfe-graphicContainer" : "mfe-container"} style={!showMainView ? { backgroundColor: 'transparent' } : {}}>
            {showMainView ? (
                <>
                    <div className="title-wrapper">
                        <div className="titleBar">
                            <div className="title">
                                {parentTitle?.map((item: any, i: number) => {
                                    return <div key={i}><span className="title-text">{item}</span><span>|</span></div>
                                })}
                                <em>{mfeTitle}</em>
                            </div>
                            <div className="actionButtons">
                                <IconButton onClick={onExpand} fileName="Icon-adjust" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={"Expand"} />
                                <IconButton
                                    onClick={(e: any) => {
                                        mfeFullscreen ? setWorkspaceState(prevState => ({
                                            ...prevState,
                                            mfeFullscreen: false
                                        })) : onClose(e)
                                    }}
                                    fileName="Icon-cross"
                                    size="medium"
                                    toolTipArrow={false}
                                    toolTipPlacement="left"
                                    toolTipText={"Close"}
                                />
                            </div>
                        </div>
                    </div>
                    {/* {entrypoint && path && <MicroFrontend path={path} entrypoint={entrypoint} sectionId={sectionId}/>} */}
                    {/**/}
                    {entrypoint && <MicroFrontend path={path ?? ""} entrypoint={entrypoint ?? ""} sectionId={sectionId} />}
                    {newDropArr?.map((item: any, i: any) => (
                        <div
                            className={`${item.expanded
                                ? "sub-module-item sub-module--expended"
                                : "sub-module-item"
                                }`}
                            style={{
                                height: `${item.height}px`,
                                width: `${item.width}px`,
                                position: "absolute",
                                top: `${item.top}px`,
                                left: `${item.left}px`,
                                paddingBottom: "0px",
                                resize: "both",
                                overflow: "auto",
                            }}
                            draggable="true"
                            onDragStart={dragstart_handler}
                            id={item.id}
                            ref={moduleRowRef}
                            key={i}
                        >
                            <Container borderRadius="roundAll" height="100%" theme="theme3" width="100%">
                                <div className="titleBar">
                                    <div className="title">{item.title}</div>
                                    <div className="actionButtons">
                                        <IconButton onClick={() => expandBox(item.id)} fileName="Icon-adjust" size="medium" toolTipArrow={false} toolTipPlacement="left" toolTipText={"Expand"} />
                                        <IconButton onClick={() => closeBox(item.id)} fileName="Icon-cross" size="medium" toolTipArrow={false} toolTipPlacement="left" toolTipText={"Close"} />
                                    </div>
                                </div>
                            </Container>
                        </div>
                    ))}

                    {/* <div className="draggable" draggable="true" onDragStart={onDragStart} onDrag={onDrag} /> */}
                </>
            ) : (
                <div className="brand-logo"></div>
            )}
            {notification.isShowNotification && <Notification />}


            <NotificationDialogComponent />
        </Container>
    </div>;
};

export default MainView;
