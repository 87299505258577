import { BuProfileEntity } from "domain/entity/BuProfile/BuProfileEntity";
import { EMPTY_USER_ENTITY, UserEntity } from "domain/entity/User/UserEntity";
import { UserGroupEntity } from "domain/entity/userGroup/UserGroupEntity";
import { axiosGetData } from "domain/repository/axios/AxiosBasicImpl";
import authAxiosInstance from "domain/repository/axios/authAxiosInstanc";
import _ from "lodash";
import { AclType, AclTypeValue, NbisivPermission } from "presentation/constant/ANAInfo/NbisivPermission";
import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { useUserVM } from "presentation/hook/User/useUserVM";

/**
 * Login assist function help to 
 * 1. retrieve the permission list by user name
 * 2. retrieve the operating companies by user name
 * 3. retrieve the BU list by user name
 * 3. retrieve the user by user name
 * 4. assist the system privilege control
 * @returns
 */
const useLoginAssist = () => {
    const userVM = useUserVM();
    /**
     * get normally user by user name
     * @param userName 
     * @returns 
     */
    const getUser = async(userName: string):Promise<UserEntity> => {
        //get user information
        const res = await axiosGetData(authAxiosInstance, `/v1/user/${userName}`, []).then((res) => {
            let user:UserEntity = EMPTY_USER_ENTITY;
            if (res.data) {
                user = res.data;
                return user;
            }
            return user;
        });
        return res;
    }

    /**
     * get user group and permission by user name
     * @param userName 
     * @returns 
     */
    const getUserGroup = async(userName: string):Promise<UserGroupEntity[]> => {
        //get user information
        const res = await axiosGetData(authAxiosInstance, `/v1/userGroupSearchByUser/${userName}`, []).then((res) => {
            let userGroups:UserGroupEntity[] = [];
            if (res.data) {
                userGroups = res.data;
                return userGroups;
            }
            return userGroups;
        });
        return res;
    }

    /**
     * Get permissions for support user
     * @param availableOperatingCompanies 
     * @returns 
     */
    const getPermissionsForSupport = async(userGroups:UserGroupEntity[], availableOperatingCompanies:any[]):Promise<{[key:string]:NbisivPermission}> => {
        let permissions:{[key:string]:NbisivPermission} = {};

        if (userGroups) {
            //initial permission
            userGroups?.map((gp) => {
                gp.permissions?.map((pm) => {
                    let acl:AclTypeValue[] = [];
                    if (pm.read) {
                        acl.push(AclType.READ);
                    }
                    if (pm.create) {
                        acl.push(AclType.CREATE);
                    }
                    if (pm.modify) {
                        acl.push(AclType.UPDATE);
                    }
                    if (pm.delete) {
                        acl.push(AclType.DELETE);
                    }
                    const permission:NbisivPermission = {
                        acl: acl,
                        tml: availableOperatingCompanies
                    }
                    permissions[pm.resource] = permission;
                    return null;
                })
                return null;
            });
            return permissions;            
        }
        return {};       
    }

    /**
     * get Operating companies for support user
     * @param operatingUnit 
     * @returns 
     */
    const getOperatingCompaniesForSupport = (buProfile:BuProfileEntity) => {
        if (buProfile) {
            const companies = buProfile.buOperatingCompanies;
            if (companies && _.isArray(companies)) {
                const buList =companies?.map((comp) => comp.operatingCompany??"");
                return buList;
            }
        }
        return [];
    }

    const getBuProfile = async(operatingUnit:string) => {
        const res = await axiosGetData(authAxiosInstance, `/v1/buprofile/bu/${operatingUnit}`, []);
        if (res) {
            if (res.data) {
                return res.data;
            }
        };
        return null;
    }

    /**
     * get permissions for normally user
     * @param user
     * @returns 
     */
    const getPermissionsForUser = async(userGroups:UserGroupEntity[]):Promise<{[key:string]:NbisivPermission}> => {
        let permissions:{[key:string]:NbisivPermission} = {};

        if (userGroups) {
            //initial permission
            userGroups?.map((gp) => {
                gp.permissions?.map((pm) => {
                    let acl:AclTypeValue[] = [];
                    if (pm.read) {
                        acl.push(AclType.READ);
                    }
                    if (pm.create) {
                        acl.push(AclType.CREATE);
                    }
                    if (pm.modify) {
                        acl.push(AclType.UPDATE);
                    }
                    if (pm.delete) {
                        acl.push(AclType.DELETE);
                    }
                    const permission:NbisivPermission = {
                        acl: acl,
                        tml: pm.attributes
                    }
                    permissions[pm.resource] = permission;
                    return null;
                })
                return null;
            });
            return permissions;            
        }
        return {};
    }

    /**
     * get operating companies for normally user
     * @param permissions 
     * @returns 
     */
    const getOperatingCompaniseForUser = (permissions:{[key:string]:NbisivPermission}):string[] => {
        if (permissions) {
            const loginPermission = permissions[Permission.LOGIN];
            if (loginPermission) {
                return loginPermission.tml;
            }
        }
        return [];
    }


    const updateLatestLoginDatetime = (userName:string) => {
        userVM.updateLatestLoginDatetime(userName);
    }
    

    return {
        getUser: getUser,
        getPermissionsForSupport: getPermissionsForSupport,
        getOperatingCompaniesForSupport: getOperatingCompaniesForSupport,
        getPermissionsForUser: getPermissionsForUser,
        getOperatingCompaniseForUser: getOperatingCompaniseForUser,
        getUserGroup: getUserGroup,
        updateLatestLoginDatetime: updateLatestLoginDatetime,
        getBuProfile: getBuProfile,
    }
}

export default useLoginAssist;