/* eslint-disable array-callback-return */
import { AlessiaRepoImpl } from "domain/repository/Alessia/AlessiaRepoImpl";
import { MiniToolbarRepositoryImpl } from "domain/repository/MiniToolbar/MiniToolbarRepositoryImpl";
import { NavigationRepositoryImpl } from "domain/repository/Navigation";
import { ServiceHandle } from "domain/utils/commonHandler/ServiceHandle";
import { commonNotification } from "domain/utils/commonNotification";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useANAInfo } from "presentation/hook/ANAInfo/useANAInfo";
import { useUserVM } from "presentation/hook/User/useUserVM";
import { useWorkspaceTracked } from "presentation/store/NavigationMenu/NavigationMenu";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { E_Notification_Type } from "presentation/view/components/workspace/Notification/Notification";
import MinitoolBarVM from "presentation/viewModel/workspace/MiniToolbarVM/MiniToolbarVM";
import NotificationVM from "presentation/viewModel/workspace/NotificationVM/NotificationVM";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DialogBox, DialogModal, DialogWithOutModal, DropdownOptions, HPHAvatar, HPHButton, HPHOverflowingMenu, IconButton, InputField } from "veronica-ui-component/dist/component/core";

const MiniToolbar = () => {
    const { WorkspaceItem, BusinessUnit } = new MiniToolbarRepositoryImpl().getMiniToolbarInfo();
    const [showList, setShowList] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [workspaceName, setWorkspaceName] = useState("");
    const WORKSPACECONSTANT = WorkspaceConstant.MiniToolBar;
    const userVM = useUserVM();

    const [workspaceItemsArr, setWorkspaceItemsArr] = useState<any>(WorkspaceItem);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [businessUnitArr, setBusinessUnitArr] = useState<any>(BusinessUnit);

    const [showWorkspaceModal, setShowWorkspaceModal] = useState({
        onSave: false,
        onDelete: false,
    });

    const [workspaceState, setWorkspaceState] = useWorkspaceTracked()
    const { navigation } = new NavigationRepositoryImpl().getNavigation(workspaceState.mainMenu);
    const { email, userName, anaKeycloak, availabeOperatingCompanies, token, currentBu, currentOperatingCompany } = useANAInfo();
    const [businessUnitDropdown, setBusinessUnitDropdown] = useState(currentOperatingCompany);
    const [keycloakConfig, setKeycloakConfig] = useState<any>(null);
    const [isInitialKeyCloak, setIsInitialKeyCloak] = useState<boolean>(false);

    const minitoolBarVM = useMemo(() =>
        MinitoolBarVM({
            dispatch: [],
            AlessiaRepo: AlessiaRepoImpl(token)
        }), [token]);

    const {
        supportingModulesList, miniToolbarArr, isSupportingModules, modulesData, workspaceDropdown,
        showVersion, mfeTitle, entrypoint, path, mainViewWidth, showMainView, sectionId
    } = workspaceState;

    const notificationVM = useMemo(() => NotificationVM({ dispatch: [setWorkspaceState], }), [setWorkspaceState]);

    const moreItems = [
        {
            label: WORKSPACECONSTANT.SET_DEFAULT_BU,
            command: () => {
                onDefault(workspaceItemsArr, workspaceDropdown);
            },
        },
        {
            label: "Save",
            command: () => {
                onSave();
            },
        },
        {
            label: "New Workspace...",
            command: () => {
                onNewWorkspace();
            },
        },
        {
            label: "Delete...",
            command: () => {
                onDelete();
            },
        },
    ];

    const modulesList = () => {
        setShowList(true);
    };

    const addModule = (item: any) => {
        const index = miniToolbarArr?.findIndex(
            (items: { id: any }) => items.id === item.id
        );
        if (index === -1) {
            item.disabled = true;
            item.drop = false;
            item.expanded = false;
            setWorkspaceState(prevState => ({
                ...prevState,
                miniToolbarArr: [...miniToolbarArr, item]
            }))
        }
    };

    const supportingModules = () => {
        return (
            <ul className="list">
                {supportingModulesList?.map((item: any, i: number) => (
                    <li key={i}>
                        <button type="button"
                            disabled={item.disabled}
                            className={item.disabled ? "disable" : "button"}
                            onClick={() => addModule(item)}>
                            {item.title}
                        </button>
                    </li>
                ))}
            </ul>
        );
    };

    const onSave = async () => {
        ServiceHandle().putData(workspaceDropdown, savedData);
        notificationVM.showNotification(E_Notification_Type.SUCCESS, commonNotification.workspaceSaved.msg(workspaceDropdown));
        setShowDialog(false);
    };

    const onNewWorkspace = async () => {
        if (showMainView) {
            setShowWorkspaceModal({ ...showWorkspaceModal, onSave: true });
            setWorkspaceName(workspaceDropdown);
        }
    }

    const onDelete = async () => {
        if (workspaceDropdown !== "") {
            setShowWorkspaceModal({ ...showWorkspaceModal, onDelete: true });
        }
    };

    const onCreateWorkspace = async () => {
        if (workspaceItemsArr?.some((e: any) => e.dropdownLabel === workspaceName)) {
            notificationVM.showNotification(E_Notification_Type.ALERT, commonNotification.workspaceExist.msg(workspaceName));
        } else {
            notificationVM.showNotification(E_Notification_Type.SUCCESS, commonNotification.workspaceSaved.msg(workspaceName));
            setWorkspaceState(prevState => ({ ...prevState, workspaceDropdown: workspaceName, }));
            ServiceHandle().postData(workspaceName, savedData);
            setWorkspaceName("");
            let newObj = {
                dropdownLabel: workspaceName,
                value: workspaceName,
            };
            workspaceItemsArr?.push(newObj);
        }
        setShowWorkspaceModal({ ...showWorkspaceModal, onSave: false });
    };

    const onDeleteWorkspace = async () => {
        notificationVM.showNotification(E_Notification_Type.SUCCESS, commonNotification.workspaceDeleted.msg(workspaceDropdown));
        setWorkspaceState(prevState => ({ ...prevState, workspaceDropdown: '', }));
        ServiceHandle().deleteData(workspaceDropdown);
        let newArr = workspaceItemsArr?.filter(
            (item: any) => item.dropdownLabel !== workspaceDropdown
        );
        setWorkspaceItemsArr(newArr);
        setShowWorkspaceModal({ ...showWorkspaceModal, onDelete: false });
    };

    const swithOperatingCompany = async (operatingCompany: string) => {
        await userVM.switchOperatingCompany(userName, operatingCompany);
    }

    const savedData = {
        versionIdentifier: {
            version: "NEW",
        },
        title: mfeTitle,
        path: path,
        entrypoint: entrypoint,
        workspaceName: workspaceName,
        main_view_width: mainViewWidth,
        supportingModules: modulesData,
    };

    const onOpenWorkspace = async (e: any) => {
        const dataFromLocal: any = ServiceHandle().getData(e.value);
        const finalData: any = JSON.parse(dataFromLocal);

        navigation?.map((ite: any) => ite?.submenu)?.filter(
            (item: any) => item)?.map((itemss: any) =>
                itemss?.map((finalItem: any) => {
                    if (finalItem?.title === finalData.title) {
                        setWorkspaceState(prevState => ({
                            ...prevState,
                            workspaceDropdown: e.value,
                            mfeTitle: finalData.title,
                            path: finalData.path,
                            entrypoint: finalData.entrypoint,
                            miniToolbarArr: finalData.supportingModules,
                            supportingModulesList: finalItem.supportingModulesList,
                            isSupportingModules: true,
                            expanded: true,
                            showMainView: true,
                        }))
                        finalItem?.supportingModulesList?.forEach((element: any) => {
                            finalData.supportingModules?.map((dropItem: any) => {
                                if (element.id === dropItem.id) {
                                    element.disabled = true;
                                }
                            });
                        })
                    }
                })
            )
    };

    const onDefault = async (array: any, key: any) => {
        const newData = array?.map((item: any) => {
            if (item.dropdownLabel === key) {
                return {
                    ...item,
                    isMaster: true,
                    lozengesLabel: "Default",
                    lozengesVariation: "Ports Horizon Blue",
                };
            } else {
                return { ...item, isMaster: false };
            }
        });
        minitoolBarVM.setDefaultBu(key, email)

        //add logic for nbisiv        
        userVM.setDefaultOperatingCompany(userName, businessUnitDropdown);

        if (array === workspaceItemsArr) {
            setWorkspaceItemsArr(newData);
        } else {
            setBusinessUnitArr(newData);
        }

        setShowDialog(false);
    };

    const handleChangePassword = useCallback(() => {
        setShowDialog(false);
        setWorkspaceState((prevState) => ({ ...prevState, showMainView: true, entrypoint: 'changePassword', path: '', sectionId: '', mfeTitle: 'Change Password', mfeSupTitle: [] }));
    }, [setWorkspaceState]);

    // const handleProfile = useCallback(() => {
    //     setShowDialog(false);
    //     setWorkspaceState((prevState) => ({ ...prevState, showMainView: true, entrypoint: 'userProfile', path: 'support', sectionId: 'profile', mfeTitle: 'Accessibility', mfeSupTitle: [] }));
    // }, [setWorkspaceState]);

    // const handleActivity = useCallback(() => {
    //     setShowDialog(false);
    //     setWorkspaceState((prevState) => ({ ...prevState, showMainView: true, entrypoint: 'userActivity', path: 'support', sectionId: 'activity', mfeTitle: 'History', mfeSupTitle: [] }));
    // }, [setWorkspaceState]);

    useEffect(() => {
        document.addEventListener("click", (e: any) => {
            const addIcon = document.querySelector(".add__icon")?.contains(e.target);
            const list = document.querySelector(".p-dialog.module--modal")?.contains(e.target);
            if (!addIcon && !list) setShowList(false);
        });
    }, []);

    useEffect(() => {
        if (!isInitialKeyCloak) return;

        const title = document.querySelector('head title');
        if (title) {
            title.innerHTML = `NBISIV - ${currentBu}${keycloakConfig.environment ? ` (${keycloakConfig.environment})` : ""}`;
        }

    }, [currentBu, isInitialKeyCloak, keycloakConfig]);

    useEffect(() => {
        if (isInitialKeyCloak) return;
        const fetchKeycloakConfig = async () => {
            try {
                const response = await fetch(`${process.env.PUBLIC_URL}/keycloak.json`);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setKeycloakConfig(data);
                setIsInitialKeyCloak(true);
            } catch (error) {
                console.error('Failed to fetch keycloak.json:', error);
            }
        };
        fetchKeycloakConfig();
    }, [isInitialKeyCloak])



    const renderSave = () => <>
        <div className="modal-header">
            <span className="name">NEW WORKSPACE</span>
            <div className="modal-actions">
                <IconButton fileName="Icon-ClearAllInkMirrored" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText="Clear" onClick={() => setWorkspaceName('')} />
                <IconButton fileName="Icon-cross" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText="Close" onClick={() => setShowWorkspaceModal({ ...showWorkspaceModal, onSave: false })} />
            </div>
        </div>
        <div className="form-control">
            <InputField type="text" label="Name" placeholder="" width="100%" value={workspaceName} onChange={(e: any) => setWorkspaceName(e.target.value)} />
        </div>
        <div className="action-button">
            <HPHButton label='Create' size='Standard' theme='Primary' disableBadge={true} onClick={() => onCreateWorkspace()} />
        </div>
    </>

    const renderDelete = () => <>
        <div className="modal-header">
            <span className="name">DELETE WORKSPACE</span>
            <div className="modal-actions">
                <IconButton fileName="Icon-cross" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText="Close" onClick={() => setShowWorkspaceModal({ ...showWorkspaceModal, onDelete: false })} />
            </div>
        </div>
        <div className="text">
            <p>Deleting the followings workspace cannot be undone.</p>
            <p>{workspaceDropdown}</p>
        </div>
        <div className="action-button">
            <HPHButton label='Delete' size='Standard' theme='Primary' disableBadge={true} onClick={() => onDeleteWorkspace()} />
        </div>
    </>;

    // const ref = useRef<HTMLFormElement>(null);

    const switchBuUrl = useMemo(() => {
        // ana authentication
        // const domainWithOutBuPrefix = getANAHostName()
        // return `https://${domainWithOutBuPrefix}/nbis/auth/public/refreshBU`
        return `${window.location.href}`
    }, [])


    const encryptSwitchBuToken = useCallback(async (userName: string, anaKeycloak: any) => {
        await userVM.switchOperatingCompany(userName, "").then(data => {
            sessionStorage.clear();
            localStorage.clear();
            anaKeycloak?.logout();
        })
    }, [userVM]);

    useEffect(() => {
        setBusinessUnitDropdown(currentOperatingCompany)
    }, [currentOperatingCompany])
    return <>
        <div className="miniToolBar">
            <div className="toolbar-container">
                <div className="action-buttons">
                    <IconButton fileName="Icon-message" size="medium" toolTipArrow={false} toolTipText="Messenger" toolTipPlacement="right" value="2" />
                    <IconButton fileName="Icon-alarm" size="medium" toolTipArrow={false} toolTipPlacement="right" toolTipText="Notification" />
                    <IconButton fileName="Icon-tech-info" size="medium" enabled={showVersion} toolTipArrow={false} toolTipPlacement="right" toolTipText="Version Information" onClick={() => setWorkspaceState(prevState => ({ ...prevState, showVersion: !showVersion }))} />
                    <>
                        <div className="seprator">
                            <span></span>
                        </div>
                        <IconButton disabled={!isSupportingModules} onClick={modulesList} fileName="Icon-add" size="medium" toolTipArrow={false} enabled={showList} toolTipPlacement="right" toolTipText="Mini Tools" className="add__icon add_modules" />
                        <DialogWithOutModal visible={showList} positions="left" dialogContent={supportingModules} className="module--modal" text="" />
                        {" "}
                    </>
                    <div className="seprator">
                        <span></span>
                    </div>
                </div>

                <div className="action-buttons" style={{ display: 'flex' }}>
                    <IconButton fileName="Icon-tech-info" size="medium" toolTipArrow={true} toolTipPlacement="right" toolTipText="Version Information" onClick={() => setWorkspaceState((prevState) => ({ ...prevState, showModalForVersionInformation: !prevState.showModalForVersionInformation }))} />
                    <div className="seprator"><span></span></div>
                </div>

                <div className="user-profile">
                    <div className="user-dropdown-wrapper">
                        <div className={`user-icon ${showDialog ? "user-icon-open" : ""}`} onClick={() => { setShowDialog(true); setShowMore(false); }}>
                            <HPHAvatar size="medium" name={userName} />
                        </div>
                        {showDialog && (
                            <div className="dialogBox-container" onMouseLeave={() => setShowDialog(false)}>
                                <DialogBox boxPosition="right" maxWidth="initial" minWidth="300px" height="auto" className="user--dropdown">
                                    <div className="dropdown-inner">
                                        <div className="user-info">
                                            <HPHAvatar size="large" name={userName} />
                                            {/* <div className="user__name">{userName}</div> */}
                                            <div className="user__email">{email}</div>
                                        </div>
                                        <div className="form-control" style={{ display: "none" }}>
                                            <NbisInputDropDown
                                                errorMessage=""
                                                field="dropdownLabel"
                                                inputType="freeText"
                                                label="Workspace"
                                                mode="single"
                                                placeholder=""
                                                value={workspaceDropdown}
                                                onChange={onOpenWorkspace}
                                                disabled={localStorage.length === 0 ? true : false}
                                                options={workspaceItemsArr}
                                                virtualScrollerOptions={{
                                                    delay: 0,
                                                    lazy: false,
                                                    showLoader: true,
                                                }}
                                                width="100%"
                                            />
                                            <div className="menu-wrapper" onMouseLeave={() => setShowMore(false)}>
                                                <IconButton fileName="Icon-more" size="medium" toolTipArrow={false} toolTipPlacement="right" toolTipText="More" onClick={() => setShowMore(!showMore)} />
                                                {showMore && <HPHOverflowingMenu menuItem={moreItems} position="right" height="auto" width="auto" className={`menu-list ${workspaceDropdown === "" ? "save-disabled" : ""}`} />}
                                            </div>
                                        </div>

                                        <div className="form-control">
                                            <NbisInputDropDown
                                                errorMessage=""
                                                field="dropdownLabel"
                                                inputType="freeText"
                                                label={WORKSPACECONSTANT.WORKING_COMPANY}
                                                mode="single"
                                                placeholder=""
                                                value={businessUnitDropdown}
                                                options={availabeOperatingCompanies?.map((bu) => ({
                                                    dropdownLabel: bu,
                                                    value: bu,
                                                    tagLabel: bu,
                                                }))}
                                                onChange={(e: DropdownOptions) => {
                                                    setBusinessUnitDropdown(e.value ?? '');
                                                    swithOperatingCompany(e.value ?? '');
                                                    setTimeout(() => {
                                                        //ref.current?.submit();
                                                        document.dispatchEvent(new CustomEvent('message', { detail: { switchingBu: true, targetUrl: switchBuUrl } }));
                                                    }) //wait for dropdown update first, and then use updated value to submit form
                                                }}
                                                virtualScrollerOptions={{
                                                    delay: 0,
                                                    lazy: false,
                                                    showLoader: true,
                                                }}
                                                width="100%"
                                            />
                                            <IconButton fileName="Icon-home" size="medium" toolTipArrow={false} toolTipPlacement="right" toolTipText="Set as default" onClick={() => onDefault(BusinessUnit, businessUnitDropdown)} />
                                        </div>
                                        <div className="form-control">
                                            <ul className="action-button">
                                                <li><div className={`link ${sectionId === 'changePassword' && 'active'}`} onClick={handleChangePassword}>Change Password</div></li>
                                                {/*<li><div className={`link ${sectionId === 'profile' && 'active'}`} onClick={handleProfile}>Accessibility</div></li>
                                                 <li><div className={`link ${sectionId === 'activity' && 'active'}`} onClick={handleActivity}>History</div></li> */}
                                            </ul>
                                        </div>
                                        <div className="form-control" style={{ marginTop: '0px' }}>
                                            <HPHButton id="logoutButton" icon="Icon-logout" label="Logout" showIcon={true} size="Standard" theme="Secondary" onClick={() => encryptSwitchBuToken(userName, anaKeycloak)} />
                                        </div>
                                    </div>
                                </DialogBox>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>

        <DialogModal visible={showWorkspaceModal?.onSave || showWorkspaceModal?.onDelete} positions="center" dialogContent={showWorkspaceModal?.onSave ? renderSave : renderDelete} className="workspace-modal" text="" />

        {showVersion && (
            <div className="version-info">
                <span>Ver:0.42.0-SNAPSHOT</span>
                <span>30/08/2022 09:00</span>
            </div>
        )}
        {/* <form ref={ref} action={switchBuUrl} method='POST' target='switch-bu-iframe'>
            <input type='hidden' value={businessUnitDropdown} name='targetBu' />
            <input type='hidden' value={token} name='token' />
        </form> */}
    </>;
};

export default MiniToolbar;
